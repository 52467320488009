const oauth = {
    domain: "holibob.auth.eu-west-1.amazoncognito.com/",
    responseType: "token",
};

export const auth = {
    region: "eu-west-1",
    userPoolId: "eu-west-1_43BYXpr67",
    userPoolWebClientId: "73t15oovb7pg70n2req45fmjt0",
    oauth,
    cookieStorage: {
        domain: typeof window !== "undefined" ? window.location.hostname : undefined,
        secure: false,
    },
};

const configShared = {
    auth,
};

export default configShared;
