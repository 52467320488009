import { useState, useMemo, useCallback } from "react";
import HolibobStorage, { HolibobStorageContext } from "utils/HolibobCookieStorage";

import { useEventCallback } from "@holibob-packages/ui-core/hooks";

export const useHolibobStorage = (
    ctx: HolibobStorageContext,
    { storageName, defaultValue, type = "object", global = "false" }: $TSFixMe
) => {
    const [value, setValue] = useState(() => {
        const valueStored =
            type === "object" ? HolibobStorage.getObject(ctx, storageName) : HolibobStorage.getItem(ctx, storageName);

        return valueStored ?? defaultValue;
    });

    const options = useMemo(() => (global ? { path: "/" } : {}), [global]);

    const refetch = useEventCallback(() => {
        const valueStored =
            type === "object" ? HolibobStorage.getObject(ctx, storageName) : HolibobStorage.getItem(ctx, storageName);

        if (valueStored) {
            setValue(valueStored);
        }
        return valueStored || defaultValue;
    });

    const onChange = useCallback(
        <T extends string | Record<string, unknown>>(value: T) => {
            type === "object"
                ? HolibobStorage.setObject(ctx, storageName, value, options)
                : HolibobStorage.setItem(ctx, storageName, value as string, options);
            setValue(value);
        },
        [ctx, options, storageName, type]
    );

    return [value, onChange, { refetch }] as const;
};

export default useHolibobStorage;
