import { useLayoutEffect, useRef, useCallback, useEffect } from "react";

const useEnhancedEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

export function useEventCallback<Args extends unknown[], Return>(
    fn: (...args: Args) => Return
): (...args: Args) => Return {
    const ref = useRef(fn);
    useEnhancedEffect(() => {
        ref.current = fn;
    });
    return useCallback((...args: Args) => (0, ref.current)(...args), []);
}
