import { useContext } from "react";

export function useContextHelper<T>(context: React.Context<T>, name = context.displayName): NonNullable<T> {
    const contextValue = useContext(context);

    if (!contextValue) {
        throw new Error(
            `Context ${
                name ?? String(context)
            } is not available, make sure you use are calling it as a child of the context provider`
        );
    }

    return contextValue;
}
