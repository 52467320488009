import { ApolloCache } from "@apollo/client";
import {
    useProductFavouritesCountQuery,
    useFavouriteProductMutation,
    ProductIsFavouriteFragmentDoc,
    ProductFavouritesCountDocument,
} from "gql";
import { useAppState } from "hooks/useAppState";
import useHolibobStorage from "hooks/useHolibobStorage";
import { useState, useCallback } from "react";
import { HolibobStorageContext } from "utils/HolibobCookieStorage";

import { useViewerIsAuthenticated } from "./useViewer";

const updateCacheOnFavouriteMutation = (cache: ApolloCache<unknown>, productId: string, isFavourite: boolean) => {
    cache.writeFragment({
        id: `ProductListItem:${productId}`,
        fragment: ProductIsFavouriteFragmentDoc,
        data: {
            isFavourite,
        },
    });
    const result = cache.readQuery<{ productList?: { recordCount?: number } }>({
        query: ProductFavouritesCountDocument,
    });
    cache.writeQuery({
        query: ProductFavouritesCountDocument,
        data: {
            productList: {
                recordCount: result?.productList?.recordCount ?? 0 + Number(isFavourite),
            },
        },
    });
};

const CONFIG = {
    storageName: "favouriteProducts",
    defaultValue: [],
    type: "object",
    global: true,
};

export const useFavouriteProductsFromStorage = (ctx: HolibobStorageContext = {}) => {
    return useHolibobStorage(ctx, CONFIG);
};

export const useFavouriteProductFromDb = () => {
    const [mutation] = useFavouriteProductMutation();

    const onChange = useCallback(
        async (productId: string, isFavourite: boolean) => {
            await mutation({
                variables: { productId, isFavourite },
                update: (cache) => updateCacheOnFavouriteMutation(cache, productId, isFavourite),
            });
        },
        [mutation]
    );

    return [onChange];
};

export const useFavouriteProductsWithStorage = (ctx: HolibobStorageContext = {}) => {
    const [favouriteProducts, setFavouriteProducts] = useFavouriteProducts();
    const [, setFavouriteProductsStorage] = useFavouriteProductsFromStorage(ctx);

    const onChange = useCallback(
        (newFavouriteProducts: $TSFixMe) => {
            setFavouriteProductsStorage(newFavouriteProducts);
            setFavouriteProducts(newFavouriteProducts);
        },
        [setFavouriteProducts, setFavouriteProductsStorage]
    );

    const hasFavourites = Boolean(favouriteProducts.length);
    return [favouriteProducts, onChange, hasFavourites] as const;
};

export const useFavouriteProduct = (id: string, isFavourite = false, ctx: HolibobStorageContext = {}) => {
    const [favouriteProducts, setFavouriteProducts] = useFavouriteProductsWithStorage(ctx);
    const [isFavouriteProduct, setIsFavouriteProduct] = useState(favouriteProducts.includes(id) || isFavourite);
    const [setIsFavouriteDb] = useFavouriteProductFromDb();
    const isAuthenticated = useViewerIsAuthenticated();

    const onChange = useCallback(
        async (value: boolean) => {
            setIsFavouriteProduct(value);
            if (isAuthenticated) {
                await setIsFavouriteDb(id, value);
            } else {
                const newFavourites = value
                    ? [...favouriteProducts, id]
                    : favouriteProducts.filter((product) => product != id);
                setFavouriteProducts(newFavourites);
            }
        },
        [isAuthenticated, setIsFavouriteDb, id, favouriteProducts, setFavouriteProducts]
    );

    return [isFavouriteProduct, onChange] as const;
};

export function useFavouriteProducts() {
    const [state, dispatch] = useAppState();
    const { favouriteProducts } = state;

    const setFavouriteProducts = useCallback(
        (favouriteProducts: string[]) => {
            dispatch({
                type: "SET_FAVOURITE_PRODUCTS",
                payload: { favouriteProducts },
            });
        },
        [dispatch]
    );

    return [favouriteProducts, setFavouriteProducts] as const;
}

export function useFavouriteProductCount(ctx: HolibobStorageContext = {}) {
    const [favouriteProducts] = useFavouriteProductsWithStorage(ctx);
    const { data: favouriteProductsCountDb } = useProductFavouritesCountQuery();
    const isAuthenticated = useViewerIsAuthenticated();

    return isAuthenticated ? favouriteProductsCountDb?.productList?.recordCount ?? 0 : favouriteProducts.length;
}

export default useFavouriteProductsWithStorage;
