import type * as cookie from "cookie";
import { parseCookies, setCookie, destroyCookie } from "nookies";

type ContextGet = Parameters<typeof parseCookies>[0];
type ContextSet = Parameters<typeof setCookie>[0];
export type HolibobStorageContext = ContextGet & ContextSet;

class HolibobCookieStorage {
    static setItem(ctx: ContextSet, key: string, value: string, options?: cookie.CookieSerializeOptions) {
        try {
            setCookie(ctx, key, value, {
                maxAge: 30 * 24 * 60 * 60,
                path: "/",
                ...(options ?? {}),
            });
        } catch (e) {
            console.error("Error storing item to Holibob Storage");
        }
    }

    static getItem(ctx: ContextGet, key: string) {
        try {
            const cookies = parseCookies(ctx);
            return cookies[key];
        } catch (e) {
            console.error("Error getting item from Holibob Storage");
            return undefined;
        }
    }

    static deleteItem(ctx: ContextSet, key: string, options?: cookie.CookieSerializeOptions) {
        try {
            return destroyCookie(ctx, key, {
                path: "/",
                ...(options ?? {}),
            });
        } catch (e) {
            console.error("Error deleting item from Holibob Storage");
            return undefined;
        }
    }

    static setObject<T>(ctx: ContextSet, key: string, value: T, options: cookie.CookieSerializeOptions = {}) {
        const jsonValue = JSON.stringify(value);
        HolibobCookieStorage.setItem(ctx, key, jsonValue, options);
    }

    static getObject<T>(ctx: ContextGet, key: string): T | null {
        const jsonValue = HolibobCookieStorage.getItem(ctx, key);
        return jsonValue ? JSON.parse(jsonValue) : null;
    }

    static getAll(ctx: ContextGet) {
        try {
            return parseCookies(ctx);
        } catch (e) {
            console.error("Error getting all from Holibob Storage");
            return undefined;
        }
    }
}

export default HolibobCookieStorage;
