import { useFavouriteProductsFromStorage } from "hooks/useFavouriteProducts";
import concat from "lodash/concat";
import noop from "lodash/noop";
import uniq from "lodash/uniq";
import without from "lodash/without";
import { createContext, useMemo, useReducer, useEffect } from "react";

const initialState = {
    visibility: ["DRAWER"],
    loading: false,
    loadingBlanker: false,
    favouriteProducts: [] as string[],
    globalSearchIsOpen: false,
};

type AppState = typeof initialState;

const reducer = (state: AppState, action: $TSFixMe) => {
    const { type, payload } = action;
    switch (type) {
        case "LOADING_START": {
            return { ...state, loading: true };
        }
        case "LOADING_STOP": {
            return { ...state, loading: false };
        }
        case "FULL_PAGE_LOADING_START": {
            return { ...state, loadingBlanker: true };
        }
        case "FULL_PAGE_LOADING_STOP": {
            return { ...state, loadingBlanker: false };
        }
        case "GLOBAL_SEARCH_OPEN": {
            return { ...state, globalSearchIsOpen: true };
        }
        case "GLOBAL_SEARCH_CLOSE": {
            return { ...state, globalSearchIsOpen: false };
        }
        case "SHOW_COMPONENT": {
            if (!action.id) return state;
            return {
                ...state,
                visibility: uniq(concat(state.visibility, action.id)),
            };
        }
        case "HIDE_COMPONENT": {
            if (!action.id) return state;
            return {
                ...state,
                visibility: without(state.visibility, action.id),
            };
        }
        case "SET_FAVOURITE_PRODUCTS": {
            const { favouriteProducts } = payload;
            return {
                ...state,
                favouriteProducts,
            };
        }

        default: {
            return state;
        }
    }
};

const AppStateContext = createContext([initialState, noop] as const);

type AppStateProviderProps = {
    children: React.ReactNode;
};
export function AppStateProvider({ children }: AppStateProviderProps) {
    const [favouriteProducts] = useFavouriteProductsFromStorage();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({
            type: "SET_FAVOURITE_PRODUCTS",
            payload: { favouriteProducts },
        });
    }, [dispatch, favouriteProducts]);

    const value = useMemo(() => [state, dispatch] as const, [state, dispatch]);

    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}

export default AppStateContext;
