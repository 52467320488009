"use client";

import { ViewerQuery } from "gql";
import { createContext } from "react";

import { Language } from "@holibob-packages/language";

import { ViewerInfo } from "../types/models/ViewerInfo";
import { useContextHelper } from "./useContextHelper";

export type Viewer = ViewerQuery["viewer"];

export const ViewerContext = createContext<ViewerInfo | null>(null);

export function useViewerContext() {
    return useContextHelper(ViewerContext, "ViewerContext");
}

export function useViewer(): Viewer {
    return useViewerContext().viewer;
}

export function useViewerPermissions(): string[] {
    const viewer = useViewer();
    return viewer.permissions;
}

export function useViewerSecurityPrincipal() {
    const viewer = useViewer();
    const { securityPrincipal } = viewer;
    return securityPrincipal;
}

export function useViewerSecurityPrincipalUser() {
    const securityPrincipal = useViewerSecurityPrincipal();
    const { user } = securityPrincipal ?? {};
    return user;
}

export function useViewerHasPermission(permissionName: string) {
    const permissions = useViewerPermissions();
    return permissions.includes(permissionName);
}

export function useViewerIsHolibobAdmin() {
    return useViewerHasPermission("HOLIBOB_ADMIN");
}

export function useViewerIsPartnerAdmin() {
    return useViewerHasPermission("PARTNER_ADMIN");
}

export function useViewerIsAuthenticated() {
    const viewer = useViewer();

    return viewer.authStatus === "AUTHENTICATED";
}

export function useViewerUsernameLabel() {
    const user = useViewerSecurityPrincipalUser();
    const { name, firstName, lastName } = user ?? {};

    if (firstName && lastName) return [firstName, lastName].join(" ");

    if (name) return name;

    return undefined;
}

export const useViewerSecurityPrincipalUserName = () => {
    const viewer = useViewer();

    const { securityPrincipal } = viewer;

    return securityPrincipal?.user?.name;
};

export const useViewerCanSee = (permissions: string[] = []) => {
    const viewerPermissions = useViewerPermissions();

    if (viewerPermissions.includes("HOLIBOB_ADMIN")) return true;

    for (const permission of permissions) {
        if (!viewerPermissions.includes(permission)) return false;
    }

    return true;
};

export const useViewerPartnerId = () => {
    const viewer = useViewer();

    const { partnerId } = viewer;

    return partnerId;
};

export const useViewerIsConsumerFacing = () => {
    const viewer = useViewer();

    const { isConsumerFacing } = viewer;

    return !!isConsumerFacing;
};

export const useViewerIsSandboxed = () => {
    const viewer = useViewer();
    const { isSandboxed } = viewer;
    return !!isSandboxed;
};

export const useViewerHasShoppingCart = () => {
    const viewer = useViewer();

    const { hasShoppingCart } = viewer;

    return !!hasShoppingCart;
};

const FALLBACK_CURRENCY_CODES = ["GBP"];
export const useViewerCurrencyCodes = (): string[] => {
    const viewer = useViewer();

    return viewer.currencyCodeData?.length ? viewer.currencyCodeData : FALLBACK_CURRENCY_CODES;
};

export const useViewerIsSearchBarSuppressed = () => {
    const viewer = useViewer();

    const { suppressNewSearch } = viewer;

    return !!suppressNewSearch;
};

export const useViewerIsLoginRequired = () => {
    const viewer = useViewer();
    const { isConsumerFacing, isSandboxed, authStatus } = viewer;

    if (authStatus === "UNAUTHENTICATED") return true;
    if (isSandboxed) return true;
    if (!isConsumerFacing) return true;

    return false;
};

export const useViewerSupportedLanguages = (): Language[] => {
    return useViewer().supportedLanguages as Language[];
};

export default useViewer;
