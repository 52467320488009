import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as GQLTypes from "@holibob-packages/graphql-types";

const defaultOptions = {} as const;
export type ConsumerFragment = {
    __typename: "Consumer";
    id: string;
    createdAt: string;
    updatedAt: string | null;
    email: string | null;
    familyName: string | null;
    givenName: string | null;
    name: string | null;
    phone: string | null;
    partnerExternalReference: string | null;
    partnerChannelId: string | null;
};

export type ConsumerTripFragment = {
    __typename: "ConsumerTrip";
    id: string;
    consumerId: string;
    createdAt: string | null;
    updatedAt: string | null;
    code: string;
    placeId: string | null;
    departureDate: string | null;
    arrivalDate: string | null;
    externalProductName: string | null;
    partnerExternalReference: string | null;
    personCount: number | null;
    destination: { __typename: "ConsumerTripDestination"; name: string | null } | null;
};

export type ConsumerTripByCodeFragment = {
    __typename: "ConsumerTrip";
    id: string;
    consumerId: string;
    partnerExternalReference: string | null;
    departureDate: string | null;
    arrivalDate: string | null;
    consumer: { __typename: "Consumer"; id: string; partnerExternalReference: string | null } | null;
};

export type ConsumerTripDestinationFragment = {
    __typename: "ConsumerTripDestination";
    name: string | null;
    image: {
        __typename: "ConsumerTripDestinationImage";
        heroImageAssetUrlList: Array<{ __typename: "Asset"; uri: string }>;
    } | null;
    place: { __typename: "PlaceNew"; name: string } | null;
};

export type ConsumerTripPlaceFragment = {
    __typename: "Place";
    id: string;
    name: string;
    lat: number | null;
    lng: number | null;
};

export type ConsumerTripSystemTagFragment = {
    __typename: "ConsumerTripSystemTag";
    systemTagId: string;
    name: string | null;
};

export type ConsumerTripViewerFragment = {
    __typename: "ConsumerTrip";
    code: string;
    consumer: { __typename: "Consumer"; id: string; givenName: string | null } | null;
    destination: {
        __typename: "ConsumerTripDestination";
        name: string | null;
        image: {
            __typename: "ConsumerTripDestinationImage";
            heroImageAssetUrlList: Array<{ __typename: "Asset"; uri: string }>;
        } | null;
        place: { __typename: "PlaceNew"; name: string } | null;
    } | null;
};

export type ConsumerTripWeatherForecastFragment = {
    __typename: "ConsumerTripWeatherForecast";
    date: string;
    type: GQLTypes.WeatherForecastTypes;
    temperature: { __typename: "Temperature"; unit: GQLTypes.TemperatureUnit; value: number };
};

export type CurationBreadcrumbFragment = { __typename: "CurationBreadcrumb"; id: string; name: string; slug: string };

export type CurationContentItemCurationFragment = {
    __typename: "CurationContentItemCuration";
    name: string;
    id: string;
    hierarchyId: string;
    slug: string;
    heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
};

export type CurationContentItemCurationParentFragment = {
    __typename: "CurationContentItemCurationParent";
    id: string;
    name: string | null;
};

export type CurationContentItemGroupFragment = {
    __typename: "CurationContentItemGroup";
    id: string;
    name: string;
    children: Array<{
        __typename: "CurationContentItemCuration";
        name: string;
        id: string;
        slug: string;
        heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
    }>;
};

export type CurationProductFragment = {
    __typename: "ProductListItem";
    id: string;
    name: string | null;
    slug: string | null;
    isFeatured: boolean | null;
    promotionType: string | null;
    guidePriceType: GQLTypes.ProductGuidePriceType | null;
    reviewCount: number | null;
    reviewRating: number | null;
    isFavourite: boolean | null;
    previewImage: { __typename: "ProductImage"; id: string } | null;
    holibobGuidePrice: {
        __typename: "Price";
        pricingData: /* eslint-disable @typescript-eslint/no-explicit-any */ any | null;
        gross: number;
        currency: string;
    } | null;
    metaList: {
        __typename: "ProductMetaListResponse";
        nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
    };
    banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
    cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
};

export type DistributionChannelAuthorBasicInfoFragment = {
    __typename: "DistributionChannelAuthor";
    name: string;
    slug: string;
    avatarImageAsset: { __typename: "Asset"; uri: string } | null;
};

export type DistributionChannelBlogPostFragment = {
    __typename: "DistributionChannelBlogPost";
    id: string;
    metaTitle: string;
    path: string;
    validFrom: string;
    summaryContentComponent: {
        __typename: "HBML";
        enhanced: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
    };
    author: {
        __typename: "DistributionChannelAuthor";
        name: string;
        slug: string;
        avatarImageAsset: { __typename: "Asset"; uri: string } | null;
    };
    categoryAssociationList: {
        __typename: "DistributionChannelPageCategoryAssociationList";
        nodes: Array<{ __typename: "DistributionChannelPageCategoryAssociation"; name: string }>;
    };
    imageAsset: { __typename: "Asset"; uri: string } | null;
};

export type HeaderMenuEntryInfoFragment = {
    __typename: "HeaderMenuEntry";
    name: string;
    openInNewTab: boolean;
    data:
        | { __typename: "HeaderMenuEntryDataCuration"; id: string }
        | {
              __typename: "HeaderMenuEntryDataDistributionChannelPage";
              id: string;
              type: GQLTypes.DistributionChannelPageTypeType | null;
              path: string;
          }
        | { __typename: "HeaderMenuEntryDataPath"; path: string }
        | null;
};

export type HeaderMenuEntryTreeFragment = {
    __typename: "HeaderMenuEntry";
    name: string;
    openInNewTab: boolean;
    children: Array<{
        __typename: "HeaderMenuEntry";
        name: string;
        openInNewTab: boolean;
        children: Array<{
            __typename: "HeaderMenuEntry";
            name: string;
            openInNewTab: boolean;
            children: Array<{
                __typename: "HeaderMenuEntry";
                name: string;
                openInNewTab: boolean;
                children: Array<{
                    __typename: "HeaderMenuEntry";
                    name: string;
                    openInNewTab: boolean;
                    data:
                        | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                        | {
                              __typename: "HeaderMenuEntryDataDistributionChannelPage";
                              id: string;
                              type: GQLTypes.DistributionChannelPageTypeType | null;
                              path: string;
                          }
                        | { __typename: "HeaderMenuEntryDataPath"; path: string }
                        | null;
                }>;
                data:
                    | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                    | {
                          __typename: "HeaderMenuEntryDataDistributionChannelPage";
                          id: string;
                          type: GQLTypes.DistributionChannelPageTypeType | null;
                          path: string;
                      }
                    | { __typename: "HeaderMenuEntryDataPath"; path: string }
                    | null;
            }>;
            data:
                | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                | {
                      __typename: "HeaderMenuEntryDataDistributionChannelPage";
                      id: string;
                      type: GQLTypes.DistributionChannelPageTypeType | null;
                      path: string;
                  }
                | { __typename: "HeaderMenuEntryDataPath"; path: string }
                | null;
        }>;
        data:
            | { __typename: "HeaderMenuEntryDataCuration"; id: string }
            | {
                  __typename: "HeaderMenuEntryDataDistributionChannelPage";
                  id: string;
                  type: GQLTypes.DistributionChannelPageTypeType | null;
                  path: string;
              }
            | { __typename: "HeaderMenuEntryDataPath"; path: string }
            | null;
    }>;
    data:
        | { __typename: "HeaderMenuEntryDataCuration"; id: string }
        | {
              __typename: "HeaderMenuEntryDataDistributionChannelPage";
              id: string;
              type: GQLTypes.DistributionChannelPageTypeType | null;
              path: string;
          }
        | { __typename: "HeaderMenuEntryDataPath"; path: string }
        | null;
};

export type LanguageFragment = {
    __typename: "Language";
    id: string;
    code: string;
    codeShort: string;
    name: string;
    graphQLCode: string;
    urlCode: string;
};

export type ProductFragment = { __typename: "ProductListItem"; isFavourite: boolean | null };

export type ProductIsFavouriteFragment = { __typename: "ProductListItem"; isFavourite: boolean | null };

export type SearchAutoCompleteCityFragment = {
    __typename: "SearchAutoCompleteCity";
    id: string;
    name: string;
    countryName: string | null;
    nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
};

export type SearchAutoCompleteCountryFragment = {
    __typename: "SearchAutoCompleteCountry";
    id: string;
    name: string;
    nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
};

export type SearchAutoCompleteProductFragment = {
    __typename: "SearchAutoCompleteProduct";
    id: string;
    name: string;
    slug: string | null;
    nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
    image: { __typename: "Asset"; uri: string } | null;
};

export type SearchAutoCompleteRegionFragment = {
    __typename: "SearchAutoCompleteRegion";
    id: string;
    name: string;
    countryName: string | null;
    nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
};

export type SearchResultFragment = {
    __typename: "Search";
    type: GQLTypes.SearchResultType;
    title: string;
    subtitle: string | null;
    id: string | null;
};

export type SitemapEntryFragment = {
    __typename: "SitemapEntry";
    modifiedAt: string;
    changeFrequency: string;
    priority: string;
    url: string;
};

export type SitemapIndexFragment = { __typename: "SitemapIndex"; index: number; modifiedAt: string };

export type SystemTagFactorFragment = {
    __typename: "SystemTagFactor";
    id: string;
    name: string;
    type: string;
    key: string;
    minChildren: number | null;
    maxChildren: number | null;
    description: string | null;
    parentId: string | null;
    level: number | null;
    narrative: string | null;
    maxConsumerChildren: number | null;
};

export type ViewerBookingWebFragment = {
    __typename: "Viewer";
    partnerId: string | null;
    partnerName: string | null;
    partnerChannelId: string | null;
    partnerChannelName: string | null;
    isSandboxed: boolean | null;
    brandId: string | null;
    isConsumerFacing: boolean | null;
    permissions: Array<string>;
    authStatus: string;
    hasShoppingCart: boolean | null;
    currencyCodeData: Array<string> | null;
    suppressNewSearch: boolean | null;
    securityPrincipal: {
        __typename: "ViewerSecurityPrincipal";
        user: {
            __typename: "ViewerSecurityPrincipalUser";
            name: string;
            firstName: string | null;
            lastName: string | null;
            avatarVaultUrl: string | null;
            avatarVaultId: string | null;
            email: string | null;
        } | null;
    } | null;
    brand: {
        __typename: "Brand";
        name: string;
        headerLogoPaddingTop: number | null;
        headerLogoPaddingBottom: number | null;
        primaryFont: string | null;
        airiness: number;
        roundness: number;
        footerLogo: { __typename: "Asset"; uri: string };
        headerLogo: { __typename: "Asset"; uri: string };
        headerContrastingLogo: { __typename: "Asset"; uri: string } | null;
        openGraphImage: { __typename: "Asset"; uri: string } | null;
        faviconImage: { __typename: "Asset"; url: string } | null;
        specialFont:
            | {
                  __typename: "FontCustom";
                  bold: { __typename: "Asset"; uri: string } | null;
                  boldItalic: { __typename: "Asset"; uri: string } | null;
                  medium: { __typename: "Asset"; uri: string } | null;
                  mediumItalic: { __typename: "Asset"; uri: string } | null;
                  regular: { __typename: "Asset"; uri: string };
                  regularItalic: { __typename: "Asset"; uri: string } | null;
              }
            | { __typename: "FontExternal"; name: string }
            | null;
        headerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
        secondaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
        footerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
        primaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
        primaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
        secondaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
        spotColours: Array<{
            __typename: "BrandSpotColour";
            shade40: string;
            shade70: string;
            shade100: string;
            shade400: string;
            shade700: string;
            index: number;
        }>;
    };
    entityConfiguration: Array<{
        __typename: "ViewerEntityConfiguration";
        id: string;
        type: string;
        subType: string;
        value: string;
        icon: {
            __typename: "EntitySystemConfigurationAssociationIcon";
            id: string;
            name: string | null;
            svg: string | null;
        } | null;
    }>;
    distributionChannel: {
        __typename: "DistributionChannel";
        id: string;
        ga4MeasurementId: string | null;
        googleTagManagerId: string | null;
        metaTitle: string | null;
        metaDescription: string | null;
        metaKeywords: string | null;
        homePageType: GQLTypes.DistributionChannelHomePageType;
        homePageCurationId: string | null;
    } | null;
    language: {
        __typename: "Language";
        id: string;
        code: string;
        codeShort: string;
        name: string;
        graphQLCode: string;
        urlCode: string;
    };
    defaultLanguage: {
        __typename: "Language";
        id: string;
        code: string;
        codeShort: string;
        name: string;
        graphQLCode: string;
        urlCode: string;
    };
    supportedLanguages: Array<{
        __typename: "Language";
        id: string;
        code: string;
        codeShort: string;
        name: string;
        graphQLCode: string;
        urlCode: string;
    }>;
};

export type ConsumerTripUpsertMutationVariables = GQLTypes.Exact<{
    input: GQLTypes.ConsumerTripInput;
}>;

export type ConsumerTripUpsertMutation = {
    __typename?: "Mutations";
    consumerTrip: {
        __typename: "ConsumerTrip";
        id: string;
        consumerId: string;
        createdAt: string | null;
        updatedAt: string | null;
        code: string;
        placeId: string | null;
        departureDate: string | null;
        arrivalDate: string | null;
        externalProductName: string | null;
        partnerExternalReference: string | null;
        personCount: number | null;
        consumer: {
            __typename: "Consumer";
            id: string;
            createdAt: string;
            updatedAt: string | null;
            email: string | null;
            familyName: string | null;
            givenName: string | null;
            name: string | null;
            phone: string | null;
            partnerExternalReference: string | null;
            partnerChannelId: string | null;
        } | null;
        place: { __typename: "Place"; id: string; name: string; lat: number | null; lng: number | null } | null;
        destination: { __typename: "ConsumerTripDestination"; name: string | null } | null;
    };
};

export type ConsumerTripEventCreateMutationVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["String"]["input"];
    type: GQLTypes.Scalars["String"]["input"];
    meta: GQLTypes.Scalars["JSON"]["input"];
}>;

export type ConsumerTripEventCreateMutation = { __typename?: "Mutations"; consumerTripEventCreate: boolean };

export type FavouriteProductMutationVariables = GQLTypes.Exact<{
    productId: GQLTypes.Scalars["String"]["input"];
    isFavourite: GQLTypes.Scalars["Boolean"]["input"];
}>;

export type FavouriteProductMutation = { __typename?: "Mutations"; favouriteProduct: boolean | null };

export type BreadcrumbListQueryVariables = GQLTypes.Exact<{
    items: Array<GQLTypes.Scalars["Hashids"]["input"]> | GQLTypes.Scalars["Hashids"]["input"];
}>;

export type BreadcrumbListQuery = {
    __typename?: "Queries";
    breadcrumbList: {
        __typename: "BreadcrumbList";
        nodes: Array<{ __typename: "CurationBreadcrumb"; id: string; name: string; slug: string }>;
    };
};

export type CategoryQueryVariables = GQLTypes.Exact<{
    categoryId: GQLTypes.Scalars["String"]["input"];
}>;

export type CategoryQuery = {
    __typename?: "Queries";
    category: { __typename: "ProductCategory"; id: string; name: string } | null;
};

export type ConsumerQueryVariables = GQLTypes.Exact<{
    id?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
}>;

export type ConsumerQuery = {
    __typename?: "Queries";
    consumer: {
        __typename: "Consumer";
        id: string;
        createdAt: string;
        updatedAt: string | null;
        email: string | null;
        familyName: string | null;
        givenName: string | null;
        name: string | null;
        phone: string | null;
        partnerExternalReference: string | null;
        partnerChannelId: string | null;
    } | null;
};

export type ConsumerTripQueryVariables = GQLTypes.Exact<{
    id: GQLTypes.Scalars["ID"]["input"];
}>;

export type ConsumerTripQuery = {
    __typename?: "Queries";
    consumerTrip: {
        __typename: "ConsumerTrip";
        id: string;
        consumerId: string;
        createdAt: string | null;
        updatedAt: string | null;
        code: string;
        placeId: string | null;
        departureDate: string | null;
        arrivalDate: string | null;
        externalProductName: string | null;
        partnerExternalReference: string | null;
        personCount: number | null;
        consumer: {
            __typename: "Consumer";
            id: string;
            createdAt: string;
            updatedAt: string | null;
            email: string | null;
            familyName: string | null;
            givenName: string | null;
            name: string | null;
            phone: string | null;
            partnerExternalReference: string | null;
            partnerChannelId: string | null;
        } | null;
        place: { __typename: "Place"; id: string; name: string; lat: number | null; lng: number | null } | null;
        systemTagList: {
            __typename: "ConsumerTripSystemTagList";
            nodes: Array<{ __typename: "ConsumerTripSystemTag"; systemTagId: string; name: string | null }>;
        } | null;
        destination: { __typename: "ConsumerTripDestination"; name: string | null } | null;
    } | null;
};

export type ConsumerTripByCodeQueryVariables = GQLTypes.Exact<{
    code: GQLTypes.Scalars["Hashids"]["input"];
}>;

export type ConsumerTripByCodeQuery = {
    __typename?: "Queries";
    consumerTripByCode: {
        __typename: "ConsumerTrip";
        id: string;
        consumerId: string;
        partnerExternalReference: string | null;
        departureDate: string | null;
        arrivalDate: string | null;
        consumer: { __typename: "Consumer"; id: string; partnerExternalReference: string | null } | null;
    } | null;
};

export type CurationContentQueryVariables = GQLTypes.Exact<{
    curationId: GQLTypes.Scalars["Hashids"]["input"];
}>;

export type CurationContentQuery = {
    __typename?: "Queries";
    curationContent: {
        __typename: "CurationContent";
        curation: {
            __typename: "CurationContentItemCuration";
            metaDescription: string | null;
            name: string;
            id: string;
            hierarchyId: string;
            slug: string;
            description: {
                __typename: "HBML";
                raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
            } | null;
            endContent: { __typename: "HBML"; raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any } | null;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        };
        curationParent: { __typename: "CurationContentItemCurationParent"; id: string; name: string | null } | null;
        children: Array<{
            __typename: "CurationContentItemCuration";
            name: string;
            id: string;
            hierarchyId: string;
            slug: string;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
        groups: Array<{
            __typename: "CurationContentItemGroup";
            id: string;
            name: string;
            children: Array<{
                __typename: "CurationContentItemCuration";
                name: string;
                id: string;
                slug: string;
                heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
            }>;
        }>;
        siblings: Array<{
            __typename: "CurationContentItemCuration";
            name: string;
            id: string;
            hierarchyId: string;
            slug: string;
            description: {
                __typename: "HBML";
                raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
            } | null;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
    } | null;
};

export type CurationContentLegacyQueryVariables = GQLTypes.Exact<{
    curationId: GQLTypes.Scalars["String"]["input"];
}>;

export type CurationContentLegacyQuery = {
    __typename?: "Queries";
    curationContentLegacy: {
        __typename: "CurationContent";
        curation: {
            __typename: "CurationContentItemCuration";
            metaDescription: string | null;
            name: string;
            id: string;
            hierarchyId: string;
            slug: string;
            description: {
                __typename: "HBML";
                raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
            } | null;
            endContent: { __typename: "HBML"; raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any } | null;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        };
        curationParent: { __typename: "CurationContentItemCurationParent"; id: string; name: string | null } | null;
        children: Array<{
            __typename: "CurationContentItemCuration";
            name: string;
            id: string;
            hierarchyId: string;
            slug: string;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
        groups: Array<{
            __typename: "CurationContentItemGroup";
            id: string;
            name: string;
            children: Array<{
                __typename: "CurationContentItemCuration";
                name: string;
                id: string;
                slug: string;
                heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
            }>;
        }>;
        siblings: Array<{
            __typename: "CurationContentItemCuration";
            name: string;
            id: string;
            hierarchyId: string;
            slug: string;
            description: {
                __typename: "HBML";
                raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
            } | null;
            heroImageAsset: { __typename: "Asset"; uri: string; id: string } | null;
        }>;
    } | null;
};

export type CurationForPlaceQueryVariables = GQLTypes.Exact<{
    placeId: GQLTypes.Scalars["ID"]["input"];
}>;

export type CurationForPlaceQuery = {
    __typename?: "Queries";
    curationList: {
        __typename: "CurationListType";
        nodes: Array<{
            __typename: "Curation";
            curationAssociationList: {
                __typename: "CurationAssociationList";
                nodes: Array<{
                    __typename: "CurationAssociationListItem";
                    id: string;
                    curationId: string;
                    curationName: string;
                    imageSource: string | null;
                    imageVaultId: string | null;
                    groupName: string | null;
                }>;
            } | null;
        }>;
    } | null;
};

export type CurationProductsQueryVariables = GQLTypes.Exact<{
    curationId: GQLTypes.Scalars["String"]["input"];
    pageSize?: GQLTypes.InputMaybe<GQLTypes.Scalars["Int"]["input"]>;
}>;

export type CurationProductsQuery = {
    __typename?: "Queries";
    productList: {
        __typename: "ProductList";
        nodes: Array<{
            __typename: "ProductListItem";
            id: string;
            name: string | null;
            slug: string | null;
            isFeatured: boolean | null;
            promotionType: string | null;
            guidePriceType: GQLTypes.ProductGuidePriceType | null;
            reviewCount: number | null;
            reviewRating: number | null;
            isFavourite: boolean | null;
            previewImage: { __typename: "ProductImage"; id: string } | null;
            holibobGuidePrice: {
                __typename: "Price";
                pricingData: /* eslint-disable @typescript-eslint/no-explicit-any */ any | null;
                gross: number;
                currency: string;
            } | null;
            metaList: {
                __typename: "ProductMetaListResponse";
                nodes: Array<{ __typename: "ProductMetaListItem"; type: string | null; value: string | null }>;
            };
            banner: { __typename: "BannerType"; text: string; backgroundColor: string } | null;
            cancellationPolicy: { __typename: "ProductCancellationPolicy"; hasFreeCancellation: boolean | null };
        }>;
    } | null;
};

export type DistributionChannelBlogAuthorListQueryVariables = GQLTypes.Exact<{
    filter?: GQLTypes.InputMaybe<GQLTypes.DistributionChannelBlogAuthorListFilter>;
}>;

export type DistributionChannelBlogAuthorListQuery = {
    __typename?: "Queries";
    distributionChannelBlogAuthorList: {
        __typename: "DistributionChannelAuthorList";
        nodes: Array<{
            __typename: "DistributionChannelAuthor";
            id: string;
            name: string;
            avatarImageAsset: { __typename: "Asset"; uri: string } | null;
        }>;
    } | null;
};

export type DistributionChannelBlogCategoryListQueryVariables = GQLTypes.Exact<{
    filter?: GQLTypes.InputMaybe<GQLTypes.DistributionChannelBlogCategoryListFilter>;
}>;

export type DistributionChannelBlogCategoryListQuery = {
    __typename?: "Queries";
    distributionChannelBlogCategoryList: {
        __typename: "DistributionChannelBlogCategoryList";
        nodes: Array<{
            __typename: "DistributionChannelPageCategory";
            name: string;
            id: string;
            categoryImageAsset: { __typename: "Asset"; uri: string } | null;
            description: {
                __typename: "HBML";
                raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
            } | null;
        }>;
    } | null;
};

export type DistributionChannelBlogPostListQueryVariables = GQLTypes.Exact<{
    filter?: GQLTypes.InputMaybe<GQLTypes.DistributionChannelBlogPostListFilter>;
    page: GQLTypes.Scalars["Int"]["input"];
    pageSize: GQLTypes.Scalars["Int"]["input"];
}>;

export type DistributionChannelBlogPostListQuery = {
    __typename?: "Queries";
    distributionChannelBlogPostList: {
        __typename: "DistributionChannelBlogPostList";
        recordCount: number;
        pageCount: number;
        nodes: Array<{
            __typename: "DistributionChannelBlogPost";
            id: string;
            metaTitle: string;
            path: string;
            validFrom: string;
            summaryContentComponent: {
                __typename: "HBML";
                enhanced: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
            };
            author: {
                __typename: "DistributionChannelAuthor";
                name: string;
                slug: string;
                avatarImageAsset: { __typename: "Asset"; uri: string } | null;
            };
            categoryAssociationList: {
                __typename: "DistributionChannelPageCategoryAssociationList";
                nodes: Array<{ __typename: "DistributionChannelPageCategoryAssociation"; name: string }>;
            };
            imageAsset: { __typename: "Asset"; uri: string } | null;
        }>;
    } | null;
};

export type DistributionChannelContentQueryVariables = GQLTypes.Exact<{
    consumerTripId?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
}>;

export type DistributionChannelContentQuery = {
    __typename?: "Queries";
    distributionChannelContent: {
        __typename: "DistributionChannelContent";
        footer: { __typename: "HBML"; enhanced: /* eslint-disable @typescript-eslint/no-explicit-any */ any } | null;
        homePage: {
            __typename: "DistributionChannelComponent";
            content: { __typename: "HBML"; raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any } | null;
        } | null;
        headerMenu: Array<{
            __typename: "HeaderMenuEntry";
            name: string;
            openInNewTab: boolean;
            children: Array<{
                __typename: "HeaderMenuEntry";
                name: string;
                openInNewTab: boolean;
                children: Array<{
                    __typename: "HeaderMenuEntry";
                    name: string;
                    openInNewTab: boolean;
                    children: Array<{
                        __typename: "HeaderMenuEntry";
                        name: string;
                        openInNewTab: boolean;
                        children: Array<{
                            __typename: "HeaderMenuEntry";
                            name: string;
                            openInNewTab: boolean;
                            data:
                                | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                                | {
                                      __typename: "HeaderMenuEntryDataDistributionChannelPage";
                                      id: string;
                                      type: GQLTypes.DistributionChannelPageTypeType | null;
                                      path: string;
                                  }
                                | { __typename: "HeaderMenuEntryDataPath"; path: string }
                                | null;
                        }>;
                        data:
                            | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                            | {
                                  __typename: "HeaderMenuEntryDataDistributionChannelPage";
                                  id: string;
                                  type: GQLTypes.DistributionChannelPageTypeType | null;
                                  path: string;
                              }
                            | { __typename: "HeaderMenuEntryDataPath"; path: string }
                            | null;
                    }>;
                    data:
                        | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                        | {
                              __typename: "HeaderMenuEntryDataDistributionChannelPage";
                              id: string;
                              type: GQLTypes.DistributionChannelPageTypeType | null;
                              path: string;
                          }
                        | { __typename: "HeaderMenuEntryDataPath"; path: string }
                        | null;
                }>;
                data:
                    | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                    | {
                          __typename: "HeaderMenuEntryDataDistributionChannelPage";
                          id: string;
                          type: GQLTypes.DistributionChannelPageTypeType | null;
                          path: string;
                      }
                    | { __typename: "HeaderMenuEntryDataPath"; path: string }
                    | null;
            }>;
            data:
                | { __typename: "HeaderMenuEntryDataCuration"; id: string }
                | {
                      __typename: "HeaderMenuEntryDataDistributionChannelPage";
                      id: string;
                      type: GQLTypes.DistributionChannelPageTypeType | null;
                      path: string;
                  }
                | { __typename: "HeaderMenuEntryDataPath"; path: string }
                | null;
        }>;
    } | null;
};

export type DistributionChannelHomePageContentQueryVariables = GQLTypes.Exact<{
    consumerTripId?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
}>;

export type DistributionChannelHomePageContentQuery = {
    __typename?: "Queries";
    distributionChannelHomePageContent:
        | {
              __typename: "DistributionChannelHomePageContentConsumerTrip";
              type: GQLTypes.DistributionChannelHomePageContentType;
              distributionChannelPagePath: string;
              page: { __typename: "HBML"; enhanced: /* eslint-disable @typescript-eslint/no-explicit-any */ any };
          }
        | {
              __typename: "DistributionChannelHomePageContentCuration";
              curationId: string;
              type: GQLTypes.DistributionChannelHomePageContentType;
          }
        | {
              __typename: "DistributionChannelHomePageContentCustom";
              type: GQLTypes.DistributionChannelHomePageContentType;
              page: { __typename: "HBML"; enhanced: /* eslint-disable @typescript-eslint/no-explicit-any */ any };
          }
        | {
              __typename: "DistributionChannelHomePageContentFeaturedProducts";
              type: GQLTypes.DistributionChannelHomePageContentType;
          }
        | null;
};

export type DistributionChannelPageContentQueryVariables = GQLTypes.Exact<{
    path: GQLTypes.Scalars["String"]["input"];
    type: GQLTypes.DistributionChannelPageTypeType;
}>;

export type DistributionChannelPageContentQuery = {
    __typename?: "Queries";
    distributionChannelPageContent: {
        __typename: "DistributionChannelPageContent";
        metaTitle: string | null;
        metaDescription: string | null;
        metaKeywords: string | null;
        placeId: string | null;
        validFrom: string | null;
        body: { __typename: "HBML"; enhanced: /* eslint-disable @typescript-eslint/no-explicit-any */ any };
        imageAsset: { __typename: "Asset"; uri: string } | null;
        author: {
            __typename: "DistributionChannelAuthor";
            name: string;
            slug: string;
            description: {
                __typename: "HBML";
                raw: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
            } | null;
            avatarImageAsset: { __typename: "Asset"; uri: string } | null;
        } | null;
        categoryAssociationList: {
            __typename: "DistributionChannelPageCategoryAssociationList";
            nodes: Array<{ __typename: "DistributionChannelPageCategoryAssociation"; slug: string; name: string }>;
        };
    } | null;
};

export type PlaceQueryVariables = GQLTypes.Exact<{
    placeId: GQLTypes.Scalars["String"]["input"];
}>;

export type PlaceQuery = {
    __typename?: "Queries";
    place: { __typename: "Place"; id: string; type: string | null; name: string } | null;
};

export type ProductFavouritesCountQueryVariables = GQLTypes.Exact<{ [key: string]: never }>;

export type ProductFavouritesCountQuery = {
    __typename?: "Queries";
    productList: { __typename: "ProductList"; recordCount: number } | null;
};

export type RedirectPathQueryVariables = GQLTypes.Exact<{
    path: GQLTypes.Scalars["String"]["input"];
}>;

export type RedirectPathQuery = {
    __typename?: "Queries";
    redirect: {
        __typename: "DistributionChannelRedirectUrlPath";
        url: string;
        type: GQLTypes.DistributionChannelRedirectType;
    } | null;
};

export type SearchQueryVariables = GQLTypes.Exact<{
    query?: GQLTypes.InputMaybe<GQLTypes.Scalars["String"]["input"]>;
}>;

export type SearchQuery = {
    __typename?: "Queries";
    search: {
        __typename: "SearchList";
        results: Array<{
            __typename: "Search";
            type: GQLTypes.SearchResultType;
            title: string;
            subtitle: string | null;
            id: string | null;
        } | null> | null;
    } | null;
};

export type SearchAutoCompleteQueryVariables = GQLTypes.Exact<{
    phrase: GQLTypes.Scalars["String"]["input"];
}>;

export type SearchAutoCompleteQuery = {
    __typename?: "Queries";
    searchAutoComplete: Array<
        | {
              __typename: "SearchAutoCompleteCity";
              id: string;
              name: string;
              countryName: string | null;
              nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
          }
        | {
              __typename: "SearchAutoCompleteCountry";
              id: string;
              name: string;
              nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
          }
        | {
              __typename: "SearchAutoCompleteProduct";
              id: string;
              name: string;
              slug: string | null;
              nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
              image: { __typename: "Asset"; uri: string } | null;
          }
        | {
              __typename: "SearchAutoCompleteRegion";
              id: string;
              name: string;
              countryName: string | null;
              nameFoundPhraseRange: { __typename: "PhraseHighlightRange"; end: number; start: number };
          }
    > | null;
};

export type SitemapEntriesAtIndexQueryVariables = GQLTypes.Exact<{
    index: GQLTypes.Scalars["NonNegativeInt"]["input"];
}>;

export type SitemapEntriesAtIndexQuery = {
    __typename?: "Queries";
    sitemapList: {
        __typename: "SitemapList";
        nodes: Array<{
            __typename: "SitemapIndex";
            entryList: {
                __typename: "SitemapEntryList";
                nodes: Array<{
                    __typename: "SitemapEntry";
                    modifiedAt: string;
                    changeFrequency: string;
                    priority: string;
                    url: string;
                }>;
            } | null;
        }>;
    } | null;
};

export type SitemapIndexQueryVariables = GQLTypes.Exact<{ [key: string]: never }>;

export type SitemapIndexQuery = {
    __typename?: "Queries";
    sitemapList: {
        __typename: "SitemapList";
        hostName: string;
        nodes: Array<{ __typename: "SitemapIndex"; index: number; modifiedAt: string }>;
    } | null;
};

export type ViewerQueryVariables = GQLTypes.Exact<{ [key: string]: never }>;

export type ViewerQuery = {
    __typename?: "Queries";
    viewer: {
        __typename: "Viewer";
        partnerId: string | null;
        partnerName: string | null;
        partnerChannelId: string | null;
        partnerChannelName: string | null;
        isSandboxed: boolean | null;
        brandId: string | null;
        isConsumerFacing: boolean | null;
        permissions: Array<string>;
        authStatus: string;
        hasShoppingCart: boolean | null;
        currencyCodeData: Array<string> | null;
        suppressNewSearch: boolean | null;
        securityPrincipal: {
            __typename: "ViewerSecurityPrincipal";
            user: {
                __typename: "ViewerSecurityPrincipalUser";
                name: string;
                firstName: string | null;
                lastName: string | null;
                avatarVaultUrl: string | null;
                avatarVaultId: string | null;
                email: string | null;
            } | null;
        } | null;
        brand: {
            __typename: "Brand";
            name: string;
            headerLogoPaddingTop: number | null;
            headerLogoPaddingBottom: number | null;
            primaryFont: string | null;
            airiness: number;
            roundness: number;
            footerLogo: { __typename: "Asset"; uri: string };
            headerLogo: { __typename: "Asset"; uri: string };
            headerContrastingLogo: { __typename: "Asset"; uri: string } | null;
            openGraphImage: { __typename: "Asset"; uri: string } | null;
            faviconImage: { __typename: "Asset"; url: string } | null;
            specialFont:
                | {
                      __typename: "FontCustom";
                      bold: { __typename: "Asset"; uri: string } | null;
                      boldItalic: { __typename: "Asset"; uri: string } | null;
                      medium: { __typename: "Asset"; uri: string } | null;
                      mediumItalic: { __typename: "Asset"; uri: string } | null;
                      regular: { __typename: "Asset"; uri: string };
                      regularItalic: { __typename: "Asset"; uri: string } | null;
                  }
                | { __typename: "FontExternal"; name: string }
                | null;
            headerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            secondaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            footerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            primaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            primaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            secondaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            spotColours: Array<{
                __typename: "BrandSpotColour";
                shade40: string;
                shade70: string;
                shade100: string;
                shade400: string;
                shade700: string;
                index: number;
            }>;
        };
        entityConfiguration: Array<{
            __typename: "ViewerEntityConfiguration";
            id: string;
            type: string;
            subType: string;
            value: string;
            icon: {
                __typename: "EntitySystemConfigurationAssociationIcon";
                id: string;
                name: string | null;
                svg: string | null;
            } | null;
        }>;
        distributionChannel: {
            __typename: "DistributionChannel";
            id: string;
            ga4MeasurementId: string | null;
            googleTagManagerId: string | null;
            metaTitle: string | null;
            metaDescription: string | null;
            metaKeywords: string | null;
            homePageType: GQLTypes.DistributionChannelHomePageType;
            homePageCurationId: string | null;
        } | null;
        language: {
            __typename: "Language";
            id: string;
            code: string;
            codeShort: string;
            name: string;
            graphQLCode: string;
            urlCode: string;
        };
        defaultLanguage: {
            __typename: "Language";
            id: string;
            code: string;
            codeShort: string;
            name: string;
            graphQLCode: string;
            urlCode: string;
        };
        supportedLanguages: Array<{
            __typename: "Language";
            id: string;
            code: string;
            codeShort: string;
            name: string;
            graphQLCode: string;
            urlCode: string;
        }>;
    };
};

export type ViewerWithConsumerTripQueryVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["ID"]["input"];
    includeConsumerTrip?: GQLTypes.InputMaybe<GQLTypes.Scalars["Boolean"]["input"]>;
}>;

export type ViewerWithConsumerTripQuery = {
    __typename?: "Queries";
    viewer: {
        __typename: "Viewer";
        partnerId: string | null;
        partnerName: string | null;
        partnerChannelId: string | null;
        partnerChannelName: string | null;
        isSandboxed: boolean | null;
        brandId: string | null;
        isConsumerFacing: boolean | null;
        permissions: Array<string>;
        authStatus: string;
        hasShoppingCart: boolean | null;
        currencyCodeData: Array<string> | null;
        suppressNewSearch: boolean | null;
        securityPrincipal: {
            __typename: "ViewerSecurityPrincipal";
            user: {
                __typename: "ViewerSecurityPrincipalUser";
                name: string;
                firstName: string | null;
                lastName: string | null;
                avatarVaultUrl: string | null;
                avatarVaultId: string | null;
                email: string | null;
            } | null;
        } | null;
        brand: {
            __typename: "Brand";
            name: string;
            headerLogoPaddingTop: number | null;
            headerLogoPaddingBottom: number | null;
            primaryFont: string | null;
            airiness: number;
            roundness: number;
            footerLogo: { __typename: "Asset"; uri: string };
            headerLogo: { __typename: "Asset"; uri: string };
            headerContrastingLogo: { __typename: "Asset"; uri: string } | null;
            openGraphImage: { __typename: "Asset"; uri: string } | null;
            faviconImage: { __typename: "Asset"; url: string } | null;
            specialFont:
                | {
                      __typename: "FontCustom";
                      bold: { __typename: "Asset"; uri: string } | null;
                      boldItalic: { __typename: "Asset"; uri: string } | null;
                      medium: { __typename: "Asset"; uri: string } | null;
                      mediumItalic: { __typename: "Asset"; uri: string } | null;
                      regular: { __typename: "Asset"; uri: string };
                      regularItalic: { __typename: "Asset"; uri: string } | null;
                  }
                | { __typename: "FontExternal"; name: string }
                | null;
            headerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            secondaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            footerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            primaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            primaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            secondaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
            spotColours: Array<{
                __typename: "BrandSpotColour";
                shade40: string;
                shade70: string;
                shade100: string;
                shade400: string;
                shade700: string;
                index: number;
            }>;
        };
        entityConfiguration: Array<{
            __typename: "ViewerEntityConfiguration";
            id: string;
            type: string;
            subType: string;
            value: string;
            icon: {
                __typename: "EntitySystemConfigurationAssociationIcon";
                id: string;
                name: string | null;
                svg: string | null;
            } | null;
        }>;
        distributionChannel: {
            __typename: "DistributionChannel";
            id: string;
            ga4MeasurementId: string | null;
            googleTagManagerId: string | null;
            metaTitle: string | null;
            metaDescription: string | null;
            metaKeywords: string | null;
            homePageType: GQLTypes.DistributionChannelHomePageType;
            homePageCurationId: string | null;
        } | null;
        language: {
            __typename: "Language";
            id: string;
            code: string;
            codeShort: string;
            name: string;
            graphQLCode: string;
            urlCode: string;
        };
        defaultLanguage: {
            __typename: "Language";
            id: string;
            code: string;
            codeShort: string;
            name: string;
            graphQLCode: string;
            urlCode: string;
        };
        supportedLanguages: Array<{
            __typename: "Language";
            id: string;
            code: string;
            codeShort: string;
            name: string;
            graphQLCode: string;
            urlCode: string;
        }>;
    };
    consumerTrip?: {
        __typename: "ConsumerTrip";
        code: string;
        consumer: { __typename: "Consumer"; id: string; givenName: string | null } | null;
        destination: {
            __typename: "ConsumerTripDestination";
            name: string | null;
            image: {
                __typename: "ConsumerTripDestinationImage";
                heroImageAssetUrlList: Array<{ __typename: "Asset"; uri: string }>;
            } | null;
            place: { __typename: "PlaceNew"; name: string } | null;
        } | null;
    } | null;
};

export type BrandColourPresetFragment = { __typename: "BrandColourPreset"; main: string; contrast: string };

export type BrandForThemeFragment = {
    __typename: "Brand";
    primaryFont: string | null;
    headerLogoPaddingTop: number | null;
    headerLogoPaddingBottom: number | null;
    airiness: number;
    roundness: number;
    specialFont:
        | {
              __typename: "FontCustom";
              bold: { __typename: "Asset"; uri: string } | null;
              boldItalic: { __typename: "Asset"; uri: string } | null;
              medium: { __typename: "Asset"; uri: string } | null;
              mediumItalic: { __typename: "Asset"; uri: string } | null;
              regular: { __typename: "Asset"; uri: string };
              regularItalic: { __typename: "Asset"; uri: string } | null;
          }
        | { __typename: "FontExternal"; name: string }
        | null;
    headerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    secondaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    footerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    primaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    primaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    secondaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    spotColours: Array<{
        __typename: "BrandSpotColour";
        shade40: string;
        shade70: string;
        shade100: string;
        shade400: string;
        shade700: string;
        index: number;
    }>;
};

type Font_FontCustom_Fragment = {
    __typename: "FontCustom";
    bold: { __typename: "Asset"; uri: string } | null;
    boldItalic: { __typename: "Asset"; uri: string } | null;
    medium: { __typename: "Asset"; uri: string } | null;
    mediumItalic: { __typename: "Asset"; uri: string } | null;
    regular: { __typename: "Asset"; uri: string };
    regularItalic: { __typename: "Asset"; uri: string } | null;
};

type Font_FontExternal_Fragment = { __typename: "FontExternal"; name: string };

export type FontFragment = Font_FontCustom_Fragment | Font_FontExternal_Fragment;

export type FontCustomFragment = {
    __typename: "FontCustom";
    bold: { __typename: "Asset"; uri: string } | null;
    boldItalic: { __typename: "Asset"; uri: string } | null;
    medium: { __typename: "Asset"; uri: string } | null;
    mediumItalic: { __typename: "Asset"; uri: string } | null;
    regular: { __typename: "Asset"; uri: string };
    regularItalic: { __typename: "Asset"; uri: string } | null;
};

export const ConsumerFragmentDoc = gql`
    fragment Consumer on Consumer {
        id
        createdAt
        updatedAt
        email
        familyName
        givenName
        name
        phone
        partnerExternalReference
        partnerChannelId
        __typename
    }
`;
export const ConsumerTripFragmentDoc = gql`
    fragment ConsumerTrip on ConsumerTrip {
        id
        consumerId
        createdAt
        updatedAt
        code
        placeId
        departureDate
        arrivalDate
        externalProductName
        partnerExternalReference
        personCount
        destination {
            name
            __typename
        }
        __typename
    }
`;
export const ConsumerTripByCodeFragmentDoc = gql`
    fragment ConsumerTripByCode on ConsumerTrip {
        id
        consumerId
        partnerExternalReference
        departureDate
        arrivalDate
        consumer {
            id
            partnerExternalReference
            __typename
        }
        __typename
    }
`;
export const ConsumerTripPlaceFragmentDoc = gql`
    fragment ConsumerTripPlace on Place {
        id
        name
        lat
        lng
        __typename
    }
`;
export const ConsumerTripSystemTagFragmentDoc = gql`
    fragment ConsumerTripSystemTag on ConsumerTripSystemTag {
        systemTagId
        name
        __typename
    }
`;
export const ConsumerTripDestinationFragmentDoc = gql`
    fragment ConsumerTripDestination on ConsumerTripDestination {
        name
        image {
            heroImageAssetUrlList {
                uri
                __typename
            }
            __typename
        }
        place {
            name
            __typename
        }
        __typename
    }
`;
export const ConsumerTripViewerFragmentDoc = gql`
    fragment ConsumerTripViewer on ConsumerTrip {
        code
        consumer {
            id
            givenName
            __typename
        }
        destination {
            ...ConsumerTripDestination
            __typename
        }
        __typename
    }
    ${ConsumerTripDestinationFragmentDoc}
`;
export const ConsumerTripWeatherForecastFragmentDoc = gql`
    fragment ConsumerTripWeatherForecast on ConsumerTripWeatherForecast {
        date
        type
        temperature {
            unit
            value
            __typename
        }
        __typename
    }
`;
export const CurationBreadcrumbFragmentDoc = gql`
    fragment CurationBreadcrumb on CurationBreadcrumb {
        id
        name
        slug
        __typename
    }
`;
export const CurationContentItemCurationFragmentDoc = gql`
    fragment CurationContentItemCuration on CurationContentItemCuration {
        name
        id
        hierarchyId
        slug
        heroImageAsset {
            uri
            id
            __typename
        }
        __typename
    }
`;
export const CurationContentItemCurationParentFragmentDoc = gql`
    fragment CurationContentItemCurationParent on CurationContentItemCurationParent {
        id
        name
        __typename
    }
`;
export const CurationContentItemGroupFragmentDoc = gql`
    fragment CurationContentItemGroup on CurationContentItemGroup {
        id
        name
        children {
            name
            id
            slug
            heroImageAsset {
                uri
                id
                __typename
            }
            __typename
        }
        __typename
    }
`;
export const CurationProductFragmentDoc = gql`
    fragment CurationProduct on ProductListItem {
        id
        name
        slug
        isFeatured
        promotionType
        guidePriceType
        reviewCount
        reviewRating
        isFavourite
        previewImage {
            id
            __typename
        }
        holibobGuidePrice {
            pricingData
            gross
            currency
            __typename
        }
        metaList {
            nodes {
                type
                value
                __typename
            }
            __typename
        }
        banner {
            text
            backgroundColor
            __typename
        }
        cancellationPolicy {
            hasFreeCancellation
            __typename
        }
        __typename
    }
`;
export const DistributionChannelAuthorBasicInfoFragmentDoc = gql`
    fragment DistributionChannelAuthorBasicInfo on DistributionChannelAuthor {
        name
        slug
        avatarImageAsset {
            uri
            __typename
        }
        __typename
    }
`;
export const DistributionChannelBlogPostFragmentDoc = gql`
    fragment DistributionChannelBlogPost on DistributionChannelBlogPost {
        id
        metaTitle
        path
        summaryContentComponent {
            enhanced
            __typename
        }
        validFrom
        author {
            ...DistributionChannelAuthorBasicInfo
            __typename
        }
        categoryAssociationList {
            nodes {
                name
                __typename
            }
            __typename
        }
        imageAsset {
            uri
            __typename
        }
        __typename
    }
    ${DistributionChannelAuthorBasicInfoFragmentDoc}
`;
export const HeaderMenuEntryInfoFragmentDoc = gql`
    fragment HeaderMenuEntryInfo on HeaderMenuEntry {
        name
        openInNewTab
        data {
            ... on HeaderMenuEntryDataPath {
                path
                __typename
            }
            ... on HeaderMenuEntryDataCuration {
                id
                __typename
            }
            ... on HeaderMenuEntryDataDistributionChannelPage {
                id
                type
                path
                __typename
            }
            __typename
        }
        __typename
    }
`;
export const HeaderMenuEntryTreeFragmentDoc = gql`
    fragment HeaderMenuEntryTree on HeaderMenuEntry {
        ...HeaderMenuEntryInfo
        children {
            ...HeaderMenuEntryInfo
            children {
                ...HeaderMenuEntryInfo
                children {
                    ...HeaderMenuEntryInfo
                    children {
                        ...HeaderMenuEntryInfo
                        __typename
                    }
                    __typename
                }
                __typename
            }
            __typename
        }
        __typename
    }
    ${HeaderMenuEntryInfoFragmentDoc}
`;
export const ProductFragmentDoc = gql`
    fragment product on ProductListItem {
        isFavourite
        __typename
    }
`;
export const ProductIsFavouriteFragmentDoc = gql`
    fragment ProductIsFavourite on ProductListItem {
        isFavourite
        __typename
    }
`;
export const SearchAutoCompleteCityFragmentDoc = gql`
    fragment SearchAutoCompleteCity on SearchAutoCompleteCity {
        id
        name
        countryName
        nameFoundPhraseRange {
            end
            start
            __typename
        }
        __typename
    }
`;
export const SearchAutoCompleteCountryFragmentDoc = gql`
    fragment SearchAutoCompleteCountry on SearchAutoCompleteCountry {
        id
        name
        nameFoundPhraseRange {
            end
            start
            __typename
        }
        __typename
    }
`;
export const SearchAutoCompleteProductFragmentDoc = gql`
    fragment SearchAutoCompleteProduct on SearchAutoCompleteProduct {
        id
        name
        slug
        nameFoundPhraseRange {
            end
            start
            __typename
        }
        image {
            uri
            __typename
        }
        __typename
    }
`;
export const SearchAutoCompleteRegionFragmentDoc = gql`
    fragment SearchAutoCompleteRegion on SearchAutoCompleteRegion {
        id
        name
        countryName
        nameFoundPhraseRange {
            end
            start
            __typename
        }
        __typename
    }
`;
export const SearchResultFragmentDoc = gql`
    fragment SearchResult on Search {
        type
        title
        subtitle
        id
        __typename
    }
`;
export const SitemapEntryFragmentDoc = gql`
    fragment SitemapEntry on SitemapEntry {
        modifiedAt
        changeFrequency
        priority
        url
        __typename
    }
`;
export const SitemapIndexFragmentDoc = gql`
    fragment SitemapIndex on SitemapIndex {
        index
        modifiedAt
        __typename
    }
`;
export const SystemTagFactorFragmentDoc = gql`
    fragment SystemTagFactor on SystemTagFactor {
        id
        name
        type
        key
        minChildren
        maxChildren
        description
        parentId
        level
        narrative
        maxConsumerChildren
        __typename
    }
`;
export const FontCustomFragmentDoc = gql`
    fragment FontCustom on FontCustom {
        bold {
            uri
            __typename
        }
        boldItalic {
            uri
            __typename
        }
        medium {
            uri
            __typename
        }
        mediumItalic {
            uri
            __typename
        }
        regular {
            uri
            __typename
        }
        regularItalic {
            uri
            __typename
        }
        __typename
    }
`;
export const FontFragmentDoc = gql`
    fragment Font on Font {
        ... on FontExternal {
            __typename
            name
        }
        ... on FontCustom {
            ...FontCustom
            __typename
        }
        __typename
    }
    ${FontCustomFragmentDoc}
`;
export const BrandColourPresetFragmentDoc = gql`
    fragment BrandColourPreset on BrandColourPreset {
        main
        contrast
        __typename
    }
`;
export const BrandForThemeFragmentDoc = gql`
    fragment BrandForTheme on Brand {
        primaryFont
        headerLogoPaddingTop
        headerLogoPaddingBottom
        specialFont {
            ...Font
            __typename
        }
        headerColour {
            ...BrandColourPreset
            __typename
        }
        secondaryColour {
            ...BrandColourPreset
            __typename
        }
        footerColour {
            ...BrandColourPreset
            __typename
        }
        primaryColour {
            ...BrandColourPreset
            __typename
        }
        primaryButtonColour {
            ...BrandColourPreset
            __typename
        }
        secondaryButtonColour {
            ...BrandColourPreset
            __typename
        }
        spotColours {
            shade40
            shade70
            shade100
            shade400
            shade700
            index
            __typename
        }
        airiness
        roundness
        __typename
    }
    ${FontFragmentDoc}
    ${BrandColourPresetFragmentDoc}
`;
export const LanguageFragmentDoc = gql`
    fragment Language on Language {
        id
        code
        codeShort
        name
        graphQLCode
        urlCode
        __typename
    }
`;
export const ViewerBookingWebFragmentDoc = gql`
    fragment ViewerBookingWeb on Viewer {
        partnerId
        partnerName
        partnerChannelId
        partnerChannelName
        isSandboxed
        brandId
        isConsumerFacing
        securityPrincipal {
            user {
                name
                firstName
                lastName
                avatarVaultUrl
                avatarVaultId
                email
                __typename
            }
            __typename
        }
        permissions
        authStatus
        hasShoppingCart
        currencyCodeData
        suppressNewSearch
        brand {
            ...BrandForTheme
            name
            footerLogo {
                uri
                __typename
            }
            headerLogoPaddingTop
            headerLogoPaddingBottom
            headerLogo {
                uri
                __typename
            }
            headerContrastingLogo {
                uri
                __typename
            }
            openGraphImage {
                uri
                __typename
            }
            faviconImage {
                url
                __typename
            }
            __typename
        }
        entityConfiguration(scope: ["BOOKING_WEB"]) {
            id
            type
            subType
            value
            icon {
                id
                name
                svg
                __typename
            }
            __typename
        }
        distributionChannel {
            id
            ga4MeasurementId
            googleTagManagerId
            metaTitle
            metaDescription
            metaKeywords
            homePageType
            homePageCurationId
            __typename
        }
        language {
            ...Language
            __typename
        }
        defaultLanguage {
            ...Language
            __typename
        }
        supportedLanguages {
            ...Language
            __typename
        }
        currencyCodeData
        __typename
    }
    ${BrandForThemeFragmentDoc}
    ${LanguageFragmentDoc}
`;
export const ConsumerTripUpsertDocument = gql`
    mutation ConsumerTripUpsert($input: ConsumerTripInput!) {
        consumerTrip(input: $input) {
            ...ConsumerTrip
            consumer {
                ...Consumer
                __typename
            }
            place {
                ...ConsumerTripPlace
                __typename
            }
            __typename
        }
    }
    ${ConsumerTripFragmentDoc}
    ${ConsumerFragmentDoc}
    ${ConsumerTripPlaceFragmentDoc}
`;
export type ConsumerTripUpsertMutationFn = Apollo.MutationFunction<
    ConsumerTripUpsertMutation,
    ConsumerTripUpsertMutationVariables
>;
export function useConsumerTripUpsertMutation(
    baseOptions?: Apollo.MutationHookOptions<ConsumerTripUpsertMutation, ConsumerTripUpsertMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConsumerTripUpsertMutation, ConsumerTripUpsertMutationVariables>(
        ConsumerTripUpsertDocument,
        options
    );
}
export type ConsumerTripUpsertMutationHookResult = ReturnType<typeof useConsumerTripUpsertMutation>;
export type ConsumerTripUpsertMutationResult = Apollo.MutationResult<ConsumerTripUpsertMutation>;
export type ConsumerTripUpsertMutationOptions = Apollo.BaseMutationOptions<
    ConsumerTripUpsertMutation,
    ConsumerTripUpsertMutationVariables
>;
export const ConsumerTripEventCreateDocument = gql`
    mutation ConsumerTripEventCreate($consumerTripId: String!, $type: String!, $meta: JSON!) {
        consumerTripEventCreate(consumerTripId: $consumerTripId, type: $type, meta: $meta)
    }
`;
export type ConsumerTripEventCreateMutationFn = Apollo.MutationFunction<
    ConsumerTripEventCreateMutation,
    ConsumerTripEventCreateMutationVariables
>;
export function useConsumerTripEventCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<ConsumerTripEventCreateMutation, ConsumerTripEventCreateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConsumerTripEventCreateMutation, ConsumerTripEventCreateMutationVariables>(
        ConsumerTripEventCreateDocument,
        options
    );
}
export type ConsumerTripEventCreateMutationHookResult = ReturnType<typeof useConsumerTripEventCreateMutation>;
export type ConsumerTripEventCreateMutationResult = Apollo.MutationResult<ConsumerTripEventCreateMutation>;
export type ConsumerTripEventCreateMutationOptions = Apollo.BaseMutationOptions<
    ConsumerTripEventCreateMutation,
    ConsumerTripEventCreateMutationVariables
>;
export const FavouriteProductDocument = gql`
    mutation FavouriteProduct($productId: String!, $isFavourite: Boolean!) {
        favouriteProduct(productId: $productId, isFavourite: $isFavourite)
    }
`;
export type FavouriteProductMutationFn = Apollo.MutationFunction<
    FavouriteProductMutation,
    FavouriteProductMutationVariables
>;
export function useFavouriteProductMutation(
    baseOptions?: Apollo.MutationHookOptions<FavouriteProductMutation, FavouriteProductMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<FavouriteProductMutation, FavouriteProductMutationVariables>(
        FavouriteProductDocument,
        options
    );
}
export type FavouriteProductMutationHookResult = ReturnType<typeof useFavouriteProductMutation>;
export type FavouriteProductMutationResult = Apollo.MutationResult<FavouriteProductMutation>;
export type FavouriteProductMutationOptions = Apollo.BaseMutationOptions<
    FavouriteProductMutation,
    FavouriteProductMutationVariables
>;
export const BreadcrumbListDocument = gql`
    query BreadcrumbList($items: [Hashids!]!) {
        breadcrumbList(items: $items) {
            nodes {
                ... on CurationBreadcrumb {
                    ...CurationBreadcrumb
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${CurationBreadcrumbFragmentDoc}
`;
export function useBreadcrumbListQuery(
    baseOptions: Apollo.QueryHookOptions<BreadcrumbListQuery, BreadcrumbListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BreadcrumbListQuery, BreadcrumbListQueryVariables>(BreadcrumbListDocument, options);
}
export function useBreadcrumbListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BreadcrumbListQuery, BreadcrumbListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BreadcrumbListQuery, BreadcrumbListQueryVariables>(BreadcrumbListDocument, options);
}
export type BreadcrumbListQueryHookResult = ReturnType<typeof useBreadcrumbListQuery>;
export type BreadcrumbListLazyQueryHookResult = ReturnType<typeof useBreadcrumbListLazyQuery>;
export type BreadcrumbListQueryResult = Apollo.QueryResult<BreadcrumbListQuery, BreadcrumbListQueryVariables>;
export const CategoryDocument = gql`
    query Category($categoryId: String!) {
        category: productCategory(id: $categoryId) {
            id
            name
            __typename
        }
    }
`;
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
}
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
}
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const ConsumerDocument = gql`
    query Consumer($id: String) {
        consumer(id: $id) {
            ...Consumer
            __typename
        }
    }
    ${ConsumerFragmentDoc}
`;
export function useConsumerQuery(baseOptions?: Apollo.QueryHookOptions<ConsumerQuery, ConsumerQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConsumerQuery, ConsumerQueryVariables>(ConsumerDocument, options);
}
export function useConsumerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumerQuery, ConsumerQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConsumerQuery, ConsumerQueryVariables>(ConsumerDocument, options);
}
export type ConsumerQueryHookResult = ReturnType<typeof useConsumerQuery>;
export type ConsumerLazyQueryHookResult = ReturnType<typeof useConsumerLazyQuery>;
export type ConsumerQueryResult = Apollo.QueryResult<ConsumerQuery, ConsumerQueryVariables>;
export const ConsumerTripDocument = gql`
    query ConsumerTrip($id: ID!) {
        consumerTrip(id: $id) {
            ...ConsumerTrip
            consumer {
                ...Consumer
                __typename
            }
            place {
                ...ConsumerTripPlace
                __typename
            }
            systemTagList {
                nodes {
                    ...ConsumerTripSystemTag
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${ConsumerTripFragmentDoc}
    ${ConsumerFragmentDoc}
    ${ConsumerTripPlaceFragmentDoc}
    ${ConsumerTripSystemTagFragmentDoc}
`;
export function useConsumerTripQuery(
    baseOptions: Apollo.QueryHookOptions<ConsumerTripQuery, ConsumerTripQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConsumerTripQuery, ConsumerTripQueryVariables>(ConsumerTripDocument, options);
}
export function useConsumerTripLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ConsumerTripQuery, ConsumerTripQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConsumerTripQuery, ConsumerTripQueryVariables>(ConsumerTripDocument, options);
}
export type ConsumerTripQueryHookResult = ReturnType<typeof useConsumerTripQuery>;
export type ConsumerTripLazyQueryHookResult = ReturnType<typeof useConsumerTripLazyQuery>;
export type ConsumerTripQueryResult = Apollo.QueryResult<ConsumerTripQuery, ConsumerTripQueryVariables>;
export const ConsumerTripByCodeDocument = gql`
    query ConsumerTripByCode($code: Hashids!) {
        consumerTripByCode(code: $code) {
            ...ConsumerTripByCode
            __typename
        }
    }
    ${ConsumerTripByCodeFragmentDoc}
`;
export function useConsumerTripByCodeQuery(
    baseOptions: Apollo.QueryHookOptions<ConsumerTripByCodeQuery, ConsumerTripByCodeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ConsumerTripByCodeQuery, ConsumerTripByCodeQueryVariables>(
        ConsumerTripByCodeDocument,
        options
    );
}
export function useConsumerTripByCodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ConsumerTripByCodeQuery, ConsumerTripByCodeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ConsumerTripByCodeQuery, ConsumerTripByCodeQueryVariables>(
        ConsumerTripByCodeDocument,
        options
    );
}
export type ConsumerTripByCodeQueryHookResult = ReturnType<typeof useConsumerTripByCodeQuery>;
export type ConsumerTripByCodeLazyQueryHookResult = ReturnType<typeof useConsumerTripByCodeLazyQuery>;
export type ConsumerTripByCodeQueryResult = Apollo.QueryResult<
    ConsumerTripByCodeQuery,
    ConsumerTripByCodeQueryVariables
>;
export const CurationContentDocument = gql`
    query CurationContent($curationId: Hashids!) {
        curationContent(curationId: $curationId) {
            curation {
                ...CurationContentItemCuration
                description {
                    raw
                    __typename
                }
                metaDescription
                endContent {
                    raw
                    __typename
                }
                __typename
            }
            curationParent {
                ...CurationContentItemCurationParent
                __typename
            }
            children {
                ...CurationContentItemCuration
                __typename
            }
            groups {
                ...CurationContentItemGroup
                __typename
            }
            siblings {
                ...CurationContentItemCuration
                description {
                    raw
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${CurationContentItemCurationFragmentDoc}
    ${CurationContentItemCurationParentFragmentDoc}
    ${CurationContentItemGroupFragmentDoc}
`;
export function useCurationContentQuery(
    baseOptions: Apollo.QueryHookOptions<CurationContentQuery, CurationContentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationContentQuery, CurationContentQueryVariables>(CurationContentDocument, options);
}
export function useCurationContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurationContentQuery, CurationContentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationContentQuery, CurationContentQueryVariables>(CurationContentDocument, options);
}
export type CurationContentQueryHookResult = ReturnType<typeof useCurationContentQuery>;
export type CurationContentLazyQueryHookResult = ReturnType<typeof useCurationContentLazyQuery>;
export type CurationContentQueryResult = Apollo.QueryResult<CurationContentQuery, CurationContentQueryVariables>;
export const CurationContentLegacyDocument = gql`
    query CurationContentLegacy($curationId: String!) {
        curationContentLegacy(curationId: $curationId) {
            curation {
                ...CurationContentItemCuration
                description {
                    raw
                    __typename
                }
                metaDescription
                endContent {
                    raw
                    __typename
                }
                __typename
            }
            curationParent {
                ...CurationContentItemCurationParent
                __typename
            }
            children {
                ...CurationContentItemCuration
                __typename
            }
            groups {
                ...CurationContentItemGroup
                __typename
            }
            siblings {
                ...CurationContentItemCuration
                description {
                    raw
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${CurationContentItemCurationFragmentDoc}
    ${CurationContentItemCurationParentFragmentDoc}
    ${CurationContentItemGroupFragmentDoc}
`;
export function useCurationContentLegacyQuery(
    baseOptions: Apollo.QueryHookOptions<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>(
        CurationContentLegacyDocument,
        options
    );
}
export function useCurationContentLegacyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationContentLegacyQuery, CurationContentLegacyQueryVariables>(
        CurationContentLegacyDocument,
        options
    );
}
export type CurationContentLegacyQueryHookResult = ReturnType<typeof useCurationContentLegacyQuery>;
export type CurationContentLegacyLazyQueryHookResult = ReturnType<typeof useCurationContentLegacyLazyQuery>;
export type CurationContentLegacyQueryResult = Apollo.QueryResult<
    CurationContentLegacyQuery,
    CurationContentLegacyQueryVariables
>;
export const CurationForPlaceDocument = gql`
    query CurationForPlace($placeId: ID!) {
        curationList(filter: { placeIdList: [$placeId] }) {
            nodes {
                curationAssociationList {
                    nodes {
                        id
                        curationId
                        curationName
                        imageSource
                        imageVaultId
                        groupName
                        __typename
                    }
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;
export function useCurationForPlaceQuery(
    baseOptions: Apollo.QueryHookOptions<CurationForPlaceQuery, CurationForPlaceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationForPlaceQuery, CurationForPlaceQueryVariables>(CurationForPlaceDocument, options);
}
export function useCurationForPlaceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurationForPlaceQuery, CurationForPlaceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationForPlaceQuery, CurationForPlaceQueryVariables>(
        CurationForPlaceDocument,
        options
    );
}
export type CurationForPlaceQueryHookResult = ReturnType<typeof useCurationForPlaceQuery>;
export type CurationForPlaceLazyQueryHookResult = ReturnType<typeof useCurationForPlaceLazyQuery>;
export type CurationForPlaceQueryResult = Apollo.QueryResult<CurationForPlaceQuery, CurationForPlaceQueryVariables>;
export const CurationProductsDocument = gql`
    query CurationProducts($curationId: String!, $pageSize: Int = 50) {
        productList(filter: { curationId: $curationId }, pageSize: $pageSize) {
            nodes {
                ...CurationProduct
                __typename
            }
            __typename
        }
    }
    ${CurationProductFragmentDoc}
`;
export function useCurationProductsQuery(
    baseOptions: Apollo.QueryHookOptions<CurationProductsQuery, CurationProductsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CurationProductsQuery, CurationProductsQueryVariables>(CurationProductsDocument, options);
}
export function useCurationProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurationProductsQuery, CurationProductsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CurationProductsQuery, CurationProductsQueryVariables>(
        CurationProductsDocument,
        options
    );
}
export type CurationProductsQueryHookResult = ReturnType<typeof useCurationProductsQuery>;
export type CurationProductsLazyQueryHookResult = ReturnType<typeof useCurationProductsLazyQuery>;
export type CurationProductsQueryResult = Apollo.QueryResult<CurationProductsQuery, CurationProductsQueryVariables>;
export const DistributionChannelBlogAuthorListDocument = gql`
    query DistributionChannelBlogAuthorList($filter: DistributionChannelBlogAuthorListFilter) {
        distributionChannelBlogAuthorList(filter: $filter) {
            nodes {
                id
                name
                avatarImageAsset {
                    uri
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;
export function useDistributionChannelBlogAuthorListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        DistributionChannelBlogAuthorListQuery,
        DistributionChannelBlogAuthorListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributionChannelBlogAuthorListQuery, DistributionChannelBlogAuthorListQueryVariables>(
        DistributionChannelBlogAuthorListDocument,
        options
    );
}
export function useDistributionChannelBlogAuthorListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DistributionChannelBlogAuthorListQuery,
        DistributionChannelBlogAuthorListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DistributionChannelBlogAuthorListQuery, DistributionChannelBlogAuthorListQueryVariables>(
        DistributionChannelBlogAuthorListDocument,
        options
    );
}
export type DistributionChannelBlogAuthorListQueryHookResult = ReturnType<
    typeof useDistributionChannelBlogAuthorListQuery
>;
export type DistributionChannelBlogAuthorListLazyQueryHookResult = ReturnType<
    typeof useDistributionChannelBlogAuthorListLazyQuery
>;
export type DistributionChannelBlogAuthorListQueryResult = Apollo.QueryResult<
    DistributionChannelBlogAuthorListQuery,
    DistributionChannelBlogAuthorListQueryVariables
>;
export const DistributionChannelBlogCategoryListDocument = gql`
    query DistributionChannelBlogCategoryList($filter: DistributionChannelBlogCategoryListFilter) {
        distributionChannelBlogCategoryList(filter: $filter) {
            nodes {
                name
                id
                categoryImageAsset {
                    uri
                    __typename
                }
                description {
                    raw
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;
export function useDistributionChannelBlogCategoryListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        DistributionChannelBlogCategoryListQuery,
        DistributionChannelBlogCategoryListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributionChannelBlogCategoryListQuery, DistributionChannelBlogCategoryListQueryVariables>(
        DistributionChannelBlogCategoryListDocument,
        options
    );
}
export function useDistributionChannelBlogCategoryListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DistributionChannelBlogCategoryListQuery,
        DistributionChannelBlogCategoryListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        DistributionChannelBlogCategoryListQuery,
        DistributionChannelBlogCategoryListQueryVariables
    >(DistributionChannelBlogCategoryListDocument, options);
}
export type DistributionChannelBlogCategoryListQueryHookResult = ReturnType<
    typeof useDistributionChannelBlogCategoryListQuery
>;
export type DistributionChannelBlogCategoryListLazyQueryHookResult = ReturnType<
    typeof useDistributionChannelBlogCategoryListLazyQuery
>;
export type DistributionChannelBlogCategoryListQueryResult = Apollo.QueryResult<
    DistributionChannelBlogCategoryListQuery,
    DistributionChannelBlogCategoryListQueryVariables
>;
export const DistributionChannelBlogPostListDocument = gql`
    query DistributionChannelBlogPostList(
        $filter: DistributionChannelBlogPostListFilter
        $page: Int!
        $pageSize: Int!
    ) {
        distributionChannelBlogPostList(filter: $filter, sort: { validFrom: desc }, page: $page, pageSize: $pageSize) {
            recordCount
            pageCount
            nodes {
                ...DistributionChannelBlogPost
                __typename
            }
            __typename
        }
    }
    ${DistributionChannelBlogPostFragmentDoc}
`;
export function useDistributionChannelBlogPostListQuery(
    baseOptions: Apollo.QueryHookOptions<
        DistributionChannelBlogPostListQuery,
        DistributionChannelBlogPostListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributionChannelBlogPostListQuery, DistributionChannelBlogPostListQueryVariables>(
        DistributionChannelBlogPostListDocument,
        options
    );
}
export function useDistributionChannelBlogPostListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DistributionChannelBlogPostListQuery,
        DistributionChannelBlogPostListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DistributionChannelBlogPostListQuery, DistributionChannelBlogPostListQueryVariables>(
        DistributionChannelBlogPostListDocument,
        options
    );
}
export type DistributionChannelBlogPostListQueryHookResult = ReturnType<typeof useDistributionChannelBlogPostListQuery>;
export type DistributionChannelBlogPostListLazyQueryHookResult = ReturnType<
    typeof useDistributionChannelBlogPostListLazyQuery
>;
export type DistributionChannelBlogPostListQueryResult = Apollo.QueryResult<
    DistributionChannelBlogPostListQuery,
    DistributionChannelBlogPostListQueryVariables
>;
export const DistributionChannelContentDocument = gql`
    query DistributionChannelContent($consumerTripId: String) {
        distributionChannelContent(consumerTripId: $consumerTripId) {
            footer {
                enhanced
                __typename
            }
            homePage {
                content {
                    raw
                    __typename
                }
                __typename
            }
            headerMenu {
                ...HeaderMenuEntryTree
                __typename
            }
            __typename
        }
    }
    ${HeaderMenuEntryTreeFragmentDoc}
`;
export function useDistributionChannelContentQuery(
    baseOptions?: Apollo.QueryHookOptions<DistributionChannelContentQuery, DistributionChannelContentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributionChannelContentQuery, DistributionChannelContentQueryVariables>(
        DistributionChannelContentDocument,
        options
    );
}
export function useDistributionChannelContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DistributionChannelContentQuery, DistributionChannelContentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DistributionChannelContentQuery, DistributionChannelContentQueryVariables>(
        DistributionChannelContentDocument,
        options
    );
}
export type DistributionChannelContentQueryHookResult = ReturnType<typeof useDistributionChannelContentQuery>;
export type DistributionChannelContentLazyQueryHookResult = ReturnType<typeof useDistributionChannelContentLazyQuery>;
export type DistributionChannelContentQueryResult = Apollo.QueryResult<
    DistributionChannelContentQuery,
    DistributionChannelContentQueryVariables
>;
export const DistributionChannelHomePageContentDocument = gql`
    query DistributionChannelHomePageContent($consumerTripId: String) {
        distributionChannelHomePageContent(consumerTripId: $consumerTripId) {
            ... on DistributionChannelHomePageContentFeaturedProducts {
                type
                __typename
            }
            ... on DistributionChannelHomePageContentCustom {
                page {
                    enhanced
                    __typename
                }
                type
                __typename
            }
            ... on DistributionChannelHomePageContentConsumerTrip {
                page {
                    enhanced
                    __typename
                }
                type
                distributionChannelPagePath
                __typename
            }
            ... on DistributionChannelHomePageContentCuration {
                curationId
                type
                __typename
            }
            __typename
        }
    }
`;
export function useDistributionChannelHomePageContentQuery(
    baseOptions?: Apollo.QueryHookOptions<
        DistributionChannelHomePageContentQuery,
        DistributionChannelHomePageContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributionChannelHomePageContentQuery, DistributionChannelHomePageContentQueryVariables>(
        DistributionChannelHomePageContentDocument,
        options
    );
}
export function useDistributionChannelHomePageContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DistributionChannelHomePageContentQuery,
        DistributionChannelHomePageContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        DistributionChannelHomePageContentQuery,
        DistributionChannelHomePageContentQueryVariables
    >(DistributionChannelHomePageContentDocument, options);
}
export type DistributionChannelHomePageContentQueryHookResult = ReturnType<
    typeof useDistributionChannelHomePageContentQuery
>;
export type DistributionChannelHomePageContentLazyQueryHookResult = ReturnType<
    typeof useDistributionChannelHomePageContentLazyQuery
>;
export type DistributionChannelHomePageContentQueryResult = Apollo.QueryResult<
    DistributionChannelHomePageContentQuery,
    DistributionChannelHomePageContentQueryVariables
>;
export const DistributionChannelPageContentDocument = gql`
    query DistributionChannelPageContent($path: String!, $type: DistributionChannelPageTypeType!) {
        distributionChannelPageContent(path: $path, type: $type) {
            body {
                enhanced
                __typename
            }
            imageAsset {
                uri
                __typename
            }
            metaTitle
            metaDescription
            metaKeywords
            placeId
            author {
                ...DistributionChannelAuthorBasicInfo
                description {
                    raw
                    __typename
                }
                __typename
            }
            validFrom
            categoryAssociationList {
                nodes {
                    slug
                    name
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${DistributionChannelAuthorBasicInfoFragmentDoc}
`;
export function useDistributionChannelPageContentQuery(
    baseOptions: Apollo.QueryHookOptions<
        DistributionChannelPageContentQuery,
        DistributionChannelPageContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributionChannelPageContentQuery, DistributionChannelPageContentQueryVariables>(
        DistributionChannelPageContentDocument,
        options
    );
}
export function useDistributionChannelPageContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DistributionChannelPageContentQuery,
        DistributionChannelPageContentQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DistributionChannelPageContentQuery, DistributionChannelPageContentQueryVariables>(
        DistributionChannelPageContentDocument,
        options
    );
}
export type DistributionChannelPageContentQueryHookResult = ReturnType<typeof useDistributionChannelPageContentQuery>;
export type DistributionChannelPageContentLazyQueryHookResult = ReturnType<
    typeof useDistributionChannelPageContentLazyQuery
>;
export type DistributionChannelPageContentQueryResult = Apollo.QueryResult<
    DistributionChannelPageContentQuery,
    DistributionChannelPageContentQueryVariables
>;
export const PlaceDocument = gql`
    query Place($placeId: String!) {
        place(id: $placeId) {
            id
            type
            name
            __typename
        }
    }
`;
export function usePlaceQuery(baseOptions: Apollo.QueryHookOptions<PlaceQuery, PlaceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PlaceQuery, PlaceQueryVariables>(PlaceDocument, options);
}
export function usePlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceQuery, PlaceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PlaceQuery, PlaceQueryVariables>(PlaceDocument, options);
}
export type PlaceQueryHookResult = ReturnType<typeof usePlaceQuery>;
export type PlaceLazyQueryHookResult = ReturnType<typeof usePlaceLazyQuery>;
export type PlaceQueryResult = Apollo.QueryResult<PlaceQuery, PlaceQueryVariables>;
export const ProductFavouritesCountDocument = gql`
    query ProductFavouritesCount {
        productList(filter: { onlyFavourites: true }) {
            recordCount
            __typename
        }
    }
`;
export function useProductFavouritesCountQuery(
    baseOptions?: Apollo.QueryHookOptions<ProductFavouritesCountQuery, ProductFavouritesCountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ProductFavouritesCountQuery, ProductFavouritesCountQueryVariables>(
        ProductFavouritesCountDocument,
        options
    );
}
export function useProductFavouritesCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ProductFavouritesCountQuery, ProductFavouritesCountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ProductFavouritesCountQuery, ProductFavouritesCountQueryVariables>(
        ProductFavouritesCountDocument,
        options
    );
}
export type ProductFavouritesCountQueryHookResult = ReturnType<typeof useProductFavouritesCountQuery>;
export type ProductFavouritesCountLazyQueryHookResult = ReturnType<typeof useProductFavouritesCountLazyQuery>;
export type ProductFavouritesCountQueryResult = Apollo.QueryResult<
    ProductFavouritesCountQuery,
    ProductFavouritesCountQueryVariables
>;
export const RedirectPathDocument = gql`
    query RedirectPath($path: String!) {
        redirect: distributionChannelRedirectUrlPathNew(path: $path) {
            url
            type
            __typename
        }
    }
`;
export function useRedirectPathQuery(
    baseOptions: Apollo.QueryHookOptions<RedirectPathQuery, RedirectPathQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RedirectPathQuery, RedirectPathQueryVariables>(RedirectPathDocument, options);
}
export function useRedirectPathLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RedirectPathQuery, RedirectPathQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RedirectPathQuery, RedirectPathQueryVariables>(RedirectPathDocument, options);
}
export type RedirectPathQueryHookResult = ReturnType<typeof useRedirectPathQuery>;
export type RedirectPathLazyQueryHookResult = ReturnType<typeof useRedirectPathLazyQuery>;
export type RedirectPathQueryResult = Apollo.QueryResult<RedirectPathQuery, RedirectPathQueryVariables>;
export const SearchDocument = gql`
    query Search($query: String) {
        search(query: $query) {
            results: nodes {
                ...SearchResult
                __typename
            }
            __typename
        }
    }
    ${SearchResultFragmentDoc}
`;
export function useSearchQuery(baseOptions?: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const SearchAutoCompleteDocument = gql`
    query SearchAutoComplete($phrase: String!) {
        searchAutoComplete(phrase: $phrase) {
            ...SearchAutoCompleteCity
            ...SearchAutoCompleteCountry
            ...SearchAutoCompleteRegion
            ...SearchAutoCompleteProduct
            __typename
        }
    }
    ${SearchAutoCompleteCityFragmentDoc}
    ${SearchAutoCompleteCountryFragmentDoc}
    ${SearchAutoCompleteRegionFragmentDoc}
    ${SearchAutoCompleteProductFragmentDoc}
`;
export function useSearchAutoCompleteQuery(
    baseOptions: Apollo.QueryHookOptions<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>(
        SearchAutoCompleteDocument,
        options
    );
}
export function useSearchAutoCompleteLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>(
        SearchAutoCompleteDocument,
        options
    );
}
export type SearchAutoCompleteQueryHookResult = ReturnType<typeof useSearchAutoCompleteQuery>;
export type SearchAutoCompleteLazyQueryHookResult = ReturnType<typeof useSearchAutoCompleteLazyQuery>;
export type SearchAutoCompleteQueryResult = Apollo.QueryResult<
    SearchAutoCompleteQuery,
    SearchAutoCompleteQueryVariables
>;
export const SitemapEntriesAtIndexDocument = gql`
    query SitemapEntriesAtIndex($index: NonNegativeInt!) {
        sitemapList(index: $index) {
            nodes {
                entryList {
                    nodes {
                        ...SitemapEntry
                        __typename
                    }
                    __typename
                }
                __typename
            }
            __typename
        }
    }
    ${SitemapEntryFragmentDoc}
`;
export function useSitemapEntriesAtIndexQuery(
    baseOptions: Apollo.QueryHookOptions<SitemapEntriesAtIndexQuery, SitemapEntriesAtIndexQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SitemapEntriesAtIndexQuery, SitemapEntriesAtIndexQueryVariables>(
        SitemapEntriesAtIndexDocument,
        options
    );
}
export function useSitemapEntriesAtIndexLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SitemapEntriesAtIndexQuery, SitemapEntriesAtIndexQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SitemapEntriesAtIndexQuery, SitemapEntriesAtIndexQueryVariables>(
        SitemapEntriesAtIndexDocument,
        options
    );
}
export type SitemapEntriesAtIndexQueryHookResult = ReturnType<typeof useSitemapEntriesAtIndexQuery>;
export type SitemapEntriesAtIndexLazyQueryHookResult = ReturnType<typeof useSitemapEntriesAtIndexLazyQuery>;
export type SitemapEntriesAtIndexQueryResult = Apollo.QueryResult<
    SitemapEntriesAtIndexQuery,
    SitemapEntriesAtIndexQueryVariables
>;
export const SitemapIndexDocument = gql`
    query SitemapIndex {
        sitemapList {
            hostName
            nodes {
                ...SitemapIndex
                __typename
            }
            __typename
        }
    }
    ${SitemapIndexFragmentDoc}
`;
export function useSitemapIndexQuery(
    baseOptions?: Apollo.QueryHookOptions<SitemapIndexQuery, SitemapIndexQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SitemapIndexQuery, SitemapIndexQueryVariables>(SitemapIndexDocument, options);
}
export function useSitemapIndexLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SitemapIndexQuery, SitemapIndexQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SitemapIndexQuery, SitemapIndexQueryVariables>(SitemapIndexDocument, options);
}
export type SitemapIndexQueryHookResult = ReturnType<typeof useSitemapIndexQuery>;
export type SitemapIndexLazyQueryHookResult = ReturnType<typeof useSitemapIndexLazyQuery>;
export type SitemapIndexQueryResult = Apollo.QueryResult<SitemapIndexQuery, SitemapIndexQueryVariables>;
export const ViewerDocument = gql`
    query Viewer {
        viewer {
            ...ViewerBookingWeb
            __typename
        }
    }
    ${ViewerBookingWebFragmentDoc}
`;
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
}
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const ViewerWithConsumerTripDocument = gql`
    query ViewerWithConsumerTrip($consumerTripId: ID!, $includeConsumerTrip: Boolean = false) {
        viewer {
            ...ViewerBookingWeb
            __typename
        }
        consumerTrip(id: $consumerTripId) @include(if: $includeConsumerTrip) {
            ...ConsumerTripViewer
            __typename
        }
    }
    ${ViewerBookingWebFragmentDoc}
    ${ConsumerTripViewerFragmentDoc}
`;
export function useViewerWithConsumerTripQuery(
    baseOptions: Apollo.QueryHookOptions<ViewerWithConsumerTripQuery, ViewerWithConsumerTripQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ViewerWithConsumerTripQuery, ViewerWithConsumerTripQueryVariables>(
        ViewerWithConsumerTripDocument,
        options
    );
}
export function useViewerWithConsumerTripLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ViewerWithConsumerTripQuery, ViewerWithConsumerTripQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ViewerWithConsumerTripQuery, ViewerWithConsumerTripQueryVariables>(
        ViewerWithConsumerTripDocument,
        options
    );
}
export type ViewerWithConsumerTripQueryHookResult = ReturnType<typeof useViewerWithConsumerTripQuery>;
export type ViewerWithConsumerTripLazyQueryHookResult = ReturnType<typeof useViewerWithConsumerTripLazyQuery>;
export type ViewerWithConsumerTripQueryResult = Apollo.QueryResult<
    ViewerWithConsumerTripQuery,
    ViewerWithConsumerTripQueryVariables
>;
