// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- It's fine here
import { useRouter as useNextRouter } from "next/navigation";
import { useContext, useMemo } from "react";

import { LocalizationContext } from "../contexts/LocalizationProvider";
import { URLLocalizer } from "./URLLocalizer";
import { useClientLocale } from "./useClientLocale";

/**
 * Returns a wrapped instance of `useRouter` from `next/navigation` that
 * will automatically localize the `href` parameters it receives.
 *
 * @example
 * ```tsx
 * 'use client';
 *
 * import { useRouter } from '@holibob-packages/ui-core/navigation';
 *
 * const router = useRouter();
 *
 * // When the user is on `/en`, the router will navigate to `/en/about`
 * router.push('/about');
 * ```
 */
export function useRouter() {
    const router = useNextRouter();
    const locale = useClientLocale();
    const viewer = useContext(LocalizationContext).viewer;

    return useMemo((): typeof router => {
        const localizer = new URLLocalizer(viewer);
        if (!locale || !localizer.shouldLocalize()) {
            return router;
        }

        const localize = (href: string) => {
            return localizer.localizeHref(href, locale, locale, window.location.pathname);
        };

        return {
            ...router,
            push(...[href, ...args]: Parameters<typeof router.push>) {
                return router.push(localize(href), ...args);
            },
            replace(...[href, ...args]: Parameters<typeof router.replace>) {
                return router.replace(localize(href), ...args);
            },
            prefetch(...[href, ...args]: Parameters<typeof router.prefetch>) {
                return router.prefetch(localize(href), ...args);
            },
        };
    }, [locale, router, viewer]);
}
