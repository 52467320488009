/* eslint-disable @typescript-eslint/no-restricted-imports */
import { isBrowser } from "#is-browser";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration, { type Duration } from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

import "./supported-locales";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!isBrowser) {
    // previously we were using en-GB from `DEFAULT_LANGUAGE` referenced from @holibob-packages/language
    // however this was unexpectedly failing for hub
    // I believe fixing that issue would cost more than just hardcoding the value here
    // it is VERY unlikely that default locale will change either way
    dayjs.locale("en-GB");
}

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime, {
    thresholds: [
        { l: "s", r: 1 },
        { l: "m", r: 1 },
        { l: "mm", r: 59, d: "minute" },
        { l: "h", r: 1 },
        { l: "hh", r: 71, d: "hour" },
        { l: "d", r: 3 },
        { l: "dd", r: 29, d: "day" },
        { l: "M", r: 1 },
        { l: "MM", r: 11, d: "month" },
        { l: "y", r: 1 },
        { l: "yy", d: "year" },
    ],
});
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);
dayjs.extend(localeData);

export { dayjs };
export type { Dayjs, Duration };
